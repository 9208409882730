// CommentsPaginator.ts
export class CommentsPaginator {
    nextPageUrl: string;
    container: HTMLElement | null = null;

    constructor(baseUrl: string) {
        this.nextPageUrl = baseUrl;
    }

    setContainer(container: HTMLElement) {
        this.container = container;
    }

    async loadResults(postId: string, limit: number = 9) {
        try {
            const response = await fetch(`${this.nextPageUrl}?post_id=${postId}&limit=${limit}`);
            const result = await response.json();

            if (result.data.comments.length > 0) {
                this.appendCommentsResults(result.data.comments);
                if (result.data.hasMore) {
                    // Show "Show more" button
                    this.nextPageUrl = result.data.next_page_url;
                } else {
                    // Hide "Show more" button
                }
            } else {
                // Show "No comments" message
            }

            // Hide loading indicator
        } catch (error) {
            console.error('Error loading comments:', error);
            // Show error toast
        }
    }

    appendCommentsResults(comments: any[]) {
        if (this.container) {
            const commentsHtml = comments.map(comment => comment.html).join('\n');
            this.container.insertAdjacentHTML('beforeend', commentsHtml);
        }
    }
  }